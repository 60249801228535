import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchPropertyList, addProperty, fetchAgentList, fetchAreaList, updateProperty, delProperty } from "@/api/charge";
import { fetchMemberList } from "@/api/member";
import AreaChina from "@/components/Area";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
import { downloadFun } from "@/utils/util"; // import {} from 'iview-area'

export default {
  name: "metterList",
  components: {
    AreaChina: AreaChina,
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        filter: {
          is_month_card: 0,
          free_charge: 0,
          non_month_user_disabled: 0
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      villageDialog: false,
      dialogForm: {
        name: "",
        agent_id: "",
        member_id: "",
        region_code: "",
        id: "",
        address: "",
        is_month_card: false,
        due_date: 0,
        non_month_user_disabled: false,
        free_charge: false,
        max_charge_duration: 8,
        is_inno: false,
        stageList: [{
          min_power: '',
          max_power: '',
          month_price: '',
          year_price: ''
        }]
      },
      textMap: {
        update: "编辑小区",
        create: "添加小区"
      },
      dialogTitle: "",
      agentOptions: [],
      agentLoading: false,
      villageLoading: false,
      villageOptions: [],
      btnLoading: false,
      citySelect: {
        province: "",
        city: "",
        region: "",
        street: ""
      },
      cityOptions: {
        provinceOps: [],
        cityOps: [],
        regionOps: [],
        streetOps: []
      },
      agentName: true,
      addresName: true,
      propertyMe: true,
      rules: {
        name: [{
          required: true,
          message: "名称不能为空"
        }],
        agent_id: [{
          required: true,
          message: "所属代理必填",
          trigger: "blur"
        }],
        member_id: [{
          required: true,
          message: "负责人必填",
          trigger: "blur"
        }],
        region_code: [{
          required: true,
          message: "请选择具体地址"
        }]
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchPropertyList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      var data = Object.assign({}, this.dialogForm);
      addProperty(data).then(function (res) {
        console.log(res); // 成功之后刷新或
        // this.dataList.unshift('返回的数据')

        _this2.$message({
          type: "success",
          message: "添加小区成功"
        });

        _this2.villageDialog = false;

        _this2.getTableData();
      }).catch(function (err) {
        _this2.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleEdit: function handleEdit(row) {
      console.log("编辑", row);
      var data = Object.assign({}, row);
      this.dialogTitle = "update";
      this.villageDialog = true;
      this.addresName = true;
      this.resetProperty(); //

      this.agentOptions.push(data.agent);
      this.villageOptions.push(data.member);
      this.dialogForm = {
        name: data.name,
        agent_id: data.agent.id,
        member_id: data.member.id,
        region_code: data.region.code,
        agentName: data.agent.name,
        memberName: data.member.name,
        adrName: data.region.name,
        id: data.id,
        address: data.address,
        is_month_card: data.is_month_card == 1 ? true : false,
        due_date: data.due_date,
        non_month_user_disabled: data.non_month_user_disabled == 1 ? true : false,
        free_charge: data.free_charge == 1 ? true : false,
        max_charge_duration: data.max_charge_duration,
        stageList: data.stageList,
        is_inno: data.is_inno
      };
      console.log(this.dialogForm.stageList);

      if (this.dialogForm.stageList.length == 0) {
        this.dialogForm.stageList = [{
          min_power: '',
          max_power: '',
          month_price: '',
          year_price: ''
        }];
      }
    },
    handleEditBtn: function handleEditBtn() {
      var _this3 = this;

      console.log(this.dialogForm);
      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this3.dialogForm);
          updateProperty(data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this3.$message({
              type: "success",
              message: "编辑小区成功"
            });

            _this3.villageDialog = false;

            _this3.getTableData();
          }).catch(function (err) {
            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    resetProperty: function resetProperty() {
      this.dialogForm = {
        name: "",
        agent_id: "",
        member_id: "",
        region_code: "",
        address: "",
        is_month_card: false,
        due_date: 0,
        non_month_user_disabled: false,
        free_charge: false,
        max_charge_duration: 8,
        stageList: [{
          min_power: '',
          max_power: '',
          month_price: '',
          year_price: ''
        }],
        is_inno: false
      };
      this.citySelect = {
        province: "",
        city: "",
        region: "",
        street: ""
      };
      this.cityOptions = {
        provinceOps: [],
        cityOps: [],
        regionOps: [],
        streetOps: []
      };
      this.agentOptions = [];
      this.villageOptions = [];
    },
    handleAdd: function handleAdd() {
      var _this4 = this;

      this.resetProperty();
      this.villageDialog = true;
      this.dialogTitle = "create";
      this.$nextTick(function () {
        _this4.$refs["dialogForm"].clearValidate();
      });
    },
    remoteAgent: function remoteAgent(query) {
      var _this5 = this;

      if (query !== "") {
        this.agentLoading = true;
        setTimeout(function () {
          _this5.agentLoading = false;
          var data = {
            keyword: query
          };
          fetchAgentList(data).then(function (res) {
            console.log(res);
            _this5.agentOptions = res.data;
          });
        }, 100);
      } else {
        this.agentOptions = [];
      }
    },
    remoteVillage: function remoteVillage(query) {
      var _this6 = this;

      console.log("village remote");

      if (query !== "") {
        this.villageLoading = true;
        setTimeout(function () {
          _this6.villageLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this6.villageOptions = res.data;
          });
        }, 100);
      } else {
        this.villageOptions = [];
      }
    },
    handleSelectCity: function handleSelectCity(code, type) {
      var _this7 = this;

      var arr = ["provinceOps", "cityOps", "regionOps", "streetOps"];
      fetchAreaList(code).then(function (res) {
        var data = res.data;
        console.log(data);
        _this7.cityOptions[arr[type]] = data;
      });
    },
    handleCityChange: function handleCityChange(type, val) {
      console.log(type, val);
      this.dialogForm.region_code = val;
      var arr2 = ["province", "city", "region", "street"];

      for (var i in arr2) {
        if (i > type) {
          this.citySelect[arr2[i]] = "";
        }
      }
    },
    handleDel: function handleDel(row) {
      var _this8 = this;

      console.log("删除小区");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u5C0F\u533A ".concat(row.name, "?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = row.id;
        delProperty(data).then(function (res) {
          console.log(res);

          _this8.$message({
            type: "success",
            message: "删除小区成功"
          });

          var index = _this8.dataList.indexOf(row);

          _this8.dataList.splice(index, 1);
        }).catch(function (err) {
          _this8.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    editAddr: function editAddr() {
      this.addresName = false;
      this.dialogForm.region_code = "";
    },
    handleAddStage: function handleAddStage() {
      this.dialogForm.stageList.push({
        min_power: '',
        max_power: '',
        month_price: '',
        year_price: ''
      });
      console.log(this.dialogForm.stageList);
    },
    handleDelStage: function handleDelStage(index) {
      this.dialogForm.stageList.splice(index, 1);
    },
    handleDownload: function handleDownload() {
      console.log("导出");
      this.btnLoading = true;
      var data = this.searchForm;
      var url = process.env.VUE_APP_URL + "/property/down?token=".concat(getToken(), "&keyword=").concat(data.keyword || "");
      console.log(url);
      downloadFun(url, "订单列表.csv");
      this.btnLoading = false;
    }
  }
};